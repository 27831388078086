import React from 'react';
import './App.css';
import styled from "@emotion/styled";
import { useTheme } from "./ThemeContext";


const Wrapper = styled("div")`
  background: ${props => props.theme.background};
  height: 100vh;
  width: 100vw;
  .is-framed{
    background-color: ${props => props.theme.background};
  }
  h1 {
    color: ${props => props.theme.body};
  }
  p {
    color: ${props => props.theme.body};
  }
  
`;


// function run(){
//   const lsDark = localStorage.getItem("dark") === "true";
//   if(lsDark){ 
//     console.log(lsDark);
//     document.body.style.background = "black";
//   }else{
//     document.body.style.background = "white";
//   }
// }

function App() {
 
  // const color = run();
  // console.log(color);
  const themeState = useTheme();
  var instagram = 'https://instagram.com/toni.astro';
  var twitter   = 'https://twitter.com/toniastro_';
  var linkedin  = 'https://linkedin.com/in/toniastro';
  var github    = 'https://github.com/toniastro';
  const image_url = themeState.dark ? 'https://res.cloudinary.com/toniastro/image/upload/v1589630430/dark-toniastro_wv7yfk.png' : 'https://res.cloudinary.com/toniastro/image/upload/v1589630431/toniastro_mmdntu.png';
  return (

    <Wrapper>
       <div className="content-wrap">
          <div className="content">
      <br/> 
        <img onClick={() => themeState.toggle()} className="profile-image rounded"  src={image_url} alt="Toni Astro"/>
        {/* <span onClick={() => themeState.toggle()}>
          {themeState.dark ? <i class="demo-icon icon-lightbulb"></i> : "Switch to Dark Mode"}
        </span> */}
        
        <h1>
            Hi, I'm Toni <span role='img'>🚀</span>,
            <br/>a Software Engineer
            <br/>from 🇳🇬.
        </h1>
        
        <p>S.D, Reading books to mitigate. <i>pseudo_enlightened</i></p>

        <p>
          I’m <a className="link-1" href={twitter} target="_blank"  rel="noopener noreferrer"><span className='aqua text'> tweeting</span> </a>, managing 1 || no &nbsp;
          <a className="link-1" href={instagram} target="_blank"  rel="noopener noreferrer"> <span className='coral text'>photo(s)</span> </a> and pushing&nbsp;                
          <a className="link-1" href={github} target="_blank"  rel="noopener noreferrer">  <span className='green text'>code</span></a> about small stuff.
        </p>
        
        <p>For shoutouts or linkups for coffee; <br/>
          <a className="link-2" href="mailto:hello@toniastro.com">hello@toniastro.com</a> 🧡
        </p>
        <p>P.S: Clicked on my memoji ?</p>
        
        <p>
            <a className="social-link twitter" target="_blank"  rel="noopener noreferrer" href={twitter}></a>
            <a className="social-link github" target="_blank"  rel="noopener noreferrer" href={github}></a>
            <a className="social-link linkedin" target="_blank"  rel="noopener noreferrer" href={linkedin}></a>
            <a className="social-link instagram" target="_blank"  rel="noopener noreferrer" href={instagram}></a>
        </p>
      </div>
      </div>
      </Wrapper>
  );
  
}

export default App;
